import React, { useRef, useEffect } from 'react'

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import 'twin.macro'

export default function ModalBase({ children, onClose }) {
  const ref = useRef(null)

  useEffect(() => {
    disableBodyScroll(ref.current)

    return () => clearAllBodyScrollLocks()
  }, [])

  return (
    <div tw="fixed inset-0 flex flex-col items-center justify-center">
      {/* modal overlay that darkens background */}
      <div onClick={onClose} tw="absolute inset-0 bg-gray-1a opacity-50" />
      {/* modal container */}
      <div
        ref={ref}
        tw="w-11/12 md:max-w-3.5xl my-4 shadow-lg z-50 overflow-y-scroll bg-white"
      >
        {children}
      </div>
    </div>
  )
}
