import React from 'react'

import tw from 'twin.macro'

export default function Modal({
  children,
  onClose,
  noX = false,
  border = false,
}) {
  return (
    <div tw="relative rounded">
      {/* modal close */}
      {!noX && (
        <div
          onClick={onClose}
          tw="absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-3 mr-3 text-gray-1a text-sm z-50"
        >
          <svg
            tw="fill-current stroke-current"
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      )}
      {/* Add margin if you want to see some of the overlay behind the modal */}
      {/* modal content */}
      <div css={[tw`py-4 text-left px-5`, border && tw`border border-gray-22`]}>
        {children}
      </div>
    </div>
  )
}
