import styled, { keyframes } from 'styled-components'
import { Spinner8 } from 'styled-icons/icomoon/Spinner8'

// ?  height: ${(props) => (props.height ? props.height : '100%')};
const Spinner = styled(Spinner8)`
  display: ${(props) => (props.show ? 'inherit' : 'none')};
  animation: ${keyframes`
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    `} 2s linear infinite;
  color: #fff;
`

export default Spinner
