import React, { useEffect, useCallback, useContext } from 'react'
import { Router } from '@reach/router'
import { HOME, THING } from '../routes'
import ViewerWrapper from '../components/ViewerWrapper'
import { useNavigate } from '../components/Link'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import ModalBase from '../components/ModalBase'
import Modal from '../components/Modal'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import App from '../components/App'

export default function ThingPage() {
  const navigate = useNavigate()

  const { modal, closeTo } = useContext(ModalRoutingContext)
  // The static route to here is forced in gatsby-node.js

  const closeOrNavBack = useCallback(
    () => navigate(modal ? closeTo : HOME, { state: { noScroll: true } }),
    [closeTo, modal, navigate]
  )

  const modalContent = (
    <ModalBase onClose={closeOrNavBack}>
      <Modal onClose={closeOrNavBack}>
        {/* Version for default language (no /en/ in path) */}
        <Router basepath={`${THING}`}>
          <ViewerWrapper path="/:thingId" />
        </Router>
        {/* Version for explicit language (/de/ in path) */}
        <Router basepath={`/:lang${THING}`}>
          <ViewerWrapper path="/:thingId" />
        </Router>
      </Modal>
    </ModalBase>
  )

  return modal ? (
    modalContent
  ) : (
    <Layout>
      <Seo title="cherrykit" />
      <App />
      {modalContent}
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
