import React, { useMemo } from 'react'
import { BiArea } from 'react-icons/bi'
import tw from 'twin.macro'

const AreaIcon = tw(BiArea)`mr-4`

export default function Measurements({ value, precision = 1, ...domProps }) {
  const measurementStr = useMemo(() => {
    if (!value) {
      return null
    }

    const toRound = 10 ** precision

    const [min, max] = value
    const measurement = max.map(
      (mx, i) => Math.round((mx - min[i]) * toRound) / toRound
    )

    if (measurement.some((m) => isNaN(m) || !isFinite(m))) {
      return null
    }

    return measurement.join(' x ')
  }, [precision, value])

  return (
    <div tw="flex items-center" {...domProps}>
      <AreaIcon /> {measurementStr || '-'} mm
    </div>
  )
}
