import { useState, useEffect, useCallback } from 'react'
import { toStl } from '@gigmade/cvg'
import fileSaver from 'file-saver'
import useBeforeUnload from 'react-use/lib/useBeforeUnload'

const warnUnsavedMsg =
  'This page is asking you to confirm that you want to leave - ' +
  'data you have entered may not be saved.'

export default function useDownloadThing({ geometry, filename }) {
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (isSaving) {
      // Give icon time to change color
      setTimeout(() => {
        var blob = new Blob([toStl(geometry)], { type: 'application/sla' })
        fileSaver.saveAs(blob, filename)
        // This one is needed to get disabled button state to ignore clicks
        // while calculating.
        setTimeout(() => {
          setIsSaving(false)
        })
      })
    }
  }, [filename, geometry, isSaving])

  const saveFile = useCallback(() => {
    setIsSaving(true)
  }, [])

  useBeforeUnload(isSaving, warnUnsavedMsg)

  return { isSaving, saveFile }
}
