import React, {
  useReducer,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import tw, { theme } from 'twin.macro'
import { PAGE_404 } from '../routes'
import { useNavigate } from './Link'
import loadable from '@loadable/component'
// import calculateFeatures, { features } from '@gigmade/three-geo-features'
import useBackend from '../useBackend'
import Toggle from './Toggle'
import Measurements from './Measurements'
import Parameters from './Parameters'
import Button from './Button'
import Spinner from './Spinner'
// import Contribute from './Contribute'
import useDownloadThing from '../useDownloadThing'
import { MdOutlineFileDownload } from 'react-icons/md'
import { resolution } from '../config.json'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const green = theme`colors.green`

const DownloadIcon = tw(MdOutlineFileDownload)`text-lg`

function safeName(name) {
  return name.replace(/[^a-zA-Z0-9]/g, '')
}

// function calculateVolumeArea(geometry) {
//   console.log(
//     'todo: useMemo or better: context, to not recalculate vol for same geometry.'
//   )
//   try {
//     const [volume, area] = calculateFeatures(geometry, [
//       features.volume,
//       features.area,
//     ])
//     console.log('volume:', volume, 'area:', area)
//     return { volume, area }
//   } catch (err) {
//     console.warn('could not calculate features!')
//     return { volume: 0, area: 0 }
//   }
// }

const geometryReducer = (state, action) => {
  // console.log('reducer called')
  switch (action.type) {
    case 'newGeometry': {
      const { geometryData } = action.payload
      return {
        ...state,
        geometryData,
        geometryReady: true,
      }
    }
    case 'setGeometryVoid': {
      return {
        ...state,
        geometryReady: false,
      }
    }
    case 'expressionChange': {
      return {
        ...state,
        geometryReady: false,
        // On editing geometry, we want fast result
        // -> if highRes set, reset to false.
        highRes: false,
      }
    }
    case 'toggleResolution': {
      const { highRes } = action.payload
      return {
        ...state,
        geometryReady: false,
        highRes,
      }
    }
    default: {
      throw new Error('unrecognized action dispatched.')
    }
  }
}

export default function ViewerWrapper({ thingId }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { item, replaceParams, loading } = useBackend(thingId)
  const [wantSave, setWantSave] = useState(false)
  const [{ geometryData, geometryReady, highRes }, dispatch] = useReducer(
    geometryReducer,
    {
      geometryData: null,
      geometryReady: false,
      highRes: false,
    }
  )

  // const [volumeArea, setVolumeArea] = useState({ volume: 0, area: 0 })

  // useEffect(() => {
  //   setVolumeArea(calculateVolumeArea(geometryData?.geometry))
  // }, [geometryData])

  const onEdit = useCallback(
    (params) => {
      dispatch({ type: 'expressionChange' })
      replaceParams(thingId, params)
    },
    [replaceParams, thingId]
  )

  const handleGeometryReady = useCallback((geometryData) => {
    dispatch({ type: 'newGeometry', payload: { geometryData } })
  }, [])

  const Viewer = useMemo(() => {
    return loadable(() => import('../components/Viewer'))
  }, [])

  const filename = useMemo(() => {
    if (!item) {
      return null
    }

    return `${safeName(item?.label)}-${Object.values(item.params).join(
      '-'
    )}.stl`
  }, [item])

  const { isSaving, saveFile } = useDownloadThing({
    geometry: geometryData?.geometry,
    filename,
  })

  useEffect(() => {
    if (wantSave) {
      if (highRes && geometryReady) {
        saveFile()
        setWantSave(false)
        return
      }

      dispatch({ type: 'toggleResolution', payload: { highRes: true } })
    }
  }, [highRes, wantSave, saveFile, geometryReady])

  if (loading) {
    return null
  }

  if (!item) {
    navigate(PAGE_404)
  }

  return (
    <div tw="flex flex-col md:flex-row items-start">
      {/* Viewer */}
      <div
        css={`
          height: 30em;
          width: 30em;
          ${tw`flex-auto flex-shrink-0 max-w-full max-h-screen p-5`}
        `}
      >
        <Viewer
          expression={item.expression}
          params={item.params}
          resolution={resolution[highRes ? 'high' : 'low']}
          color={0xbbbbbb}
          clearColor={green}
          onReady={handleGeometryReady}
          mouseControl={true}
        />
      </div>
      {/* Right side */}
      <div tw="flex-auto self-stretch flex flex-col justify-between max-w-full p-5 space-y-4">
        <Parameters
          title={t('thing.measures')}
          definitions={item.expression.params}
          params={item.params}
          onEdit={onEdit}
        />
        <Measurements value={geometryData?.bounding} precision={1} />
        {/* Wrap in div so it breaks together on small screens.
        self-start makes the div just as wide as its content, which allows our
        2nd div group to grow to available width (useful when wrapped) without
        growing in non-wrapped and breaking the space between. */}
        <div tw="mt-4 flex">
          <Toggle
            tw="mr-4"
            active={highRes}
            onToggle={() => {
              dispatch({
                type: 'toggleResolution',
                payload: { highRes: !highRes },
              })
            }}
          >
            {t('thing.highRes')}
          </Toggle>
          {!geometryReady && <div tw="italic">Please wait..</div>}
        </div>
        <div tw="flex flex-wrap items-center self-start">
          <div tw="mt-4 flex flex-grow justify-around items-center">
            <Button
              tw="mr-4"
              onClick={() => {
                const props = { filename, thingId }

                // window.gtag && window.gtag('event', 'begin_checkout', props)
                window.plausible &&
                  window.plausible('begin_checkout', { props })

                navigate(`/shopfeedback/${thingId}`)
              }}
            >
              {t('thing.buy')}
            </Button>
            <Button
              tw="mr-4 relative"
              disabled={wantSave || isSaving}
              onClick={() => {
                const props = { filename, thingId }
                // window.gtag && window.gtag('event', 'file_download', props)
                window.plausible && window.plausible('file_download', { props })

                setWantSave(true)
              }}
              css={[
                tw`flex flex-row items-center`,
                (wantSave || isSaving) && tw`text-gray-9d cursor-default`,
              ]}
            >
              <Spinner
                show={wantSave || isSaving}
                tw="absolute m-auto inset-0.5 h-5"
              ></Spinner>
              <DownloadIcon css={[isSaving && tw`text-cherry`]} />
              <div tw="text-xs">&nbsp;STL</div>
            </Button>
            {/* <Contribute thingId={thingId} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
