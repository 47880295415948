import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

const Switch = styled.label`
  ${tw`relative inline-block ml-2 h-1`}
  width: 30px;
  height: 17px;
  /* Hide default HTML checkbox */
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const Slider = styled.div`
  ${tw`absolute inset-0 cursor-pointer rounded-full duration-300 bg-gray-9d`}
  ${(props) => (props.checked ? tw`bg-green` : '')}

  &:before {
    ${tw`absolute rounded-full duration-300 bg-white`}
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    ${(props) => (props.checked ? 'transform: translateX(13px);' : '')};
  }
`

export default function Toggle({
  active,
  disabled,
  onToggle,
  children,
  ...domProps
}) {
  return (
    <div
      css={[disabled && tw`opacity-50`, tw`whitespace-nowrap`]}
      {...domProps}
    >
      {children}
      <Switch>
        <input
          type="checkbox"
          disabled={disabled}
          checked={active}
          onChange={onToggle}
        />
        <Slider checked={active} />
      </Switch>
    </div>
  )
}
