import React, { useMemo } from 'react'
import Form from '@rjsf/material-ui'
import styled from 'styled-components'

const HideButton = styled.div`
  & button {
    display: none;
  }
`

function extractValidation(sourceDefinitions) {
  const definitions = {},
    validations = []
  Object.keys(sourceDefinitions).forEach((key) => {
    // extract definitions
    const { validate, ...definition } = sourceDefinitions[key]
    definitions[key] = definition
    if (validate) {
      validations.push({ key, validate })
    }
  })

  function validate(params, errors) {
    validations.forEach((validation) => {
      const error = validation.validate(params)
      if (error) {
        errors[validation.key].addError(error)
      }
    })

    return errors
  }
  return { definitions, validate }
}

const uiSchemaFrom = (definitions) => {
  const uiSchema = Object.assign(
    {},
    ...Object.keys(definitions)
      .filter((p) => definitions[p].type === 'integer')
      .map((p) => ({ [p]: { 'ui:widget': 'range' } }))
  )

  return uiSchema
}

const formatSchema = (definitions) => ({
  type: 'object',
  properties: definitions,
})

const Parameters = ({
  definitions: sourceDefinitions,
  params,
  onEdit,
  title,
}) => {
  if (!sourceDefinitions) {
    // TODO: track when params not needed but then need to be sure we have no input arguments
    // to function.
    throw new Error('Thing needs a `params` object.')
  }
  const schemaProps = useMemo(() => {
    const { definitions, validate } = extractValidation(sourceDefinitions)
    return {
      validate,
      schema: formatSchema(definitions),
      uiSchema: uiSchemaFrom(definitions),
    }
  }, [sourceDefinitions])

  return (
    <HideButton>
      <div>{title}:</div>
      <Form
        liveValidate
        showErrorList={false}
        {...schemaProps}
        formData={params}
        onChange={({ formData }) => onEdit(formData)}
      />
    </HideButton>
  )
}

export default Parameters
